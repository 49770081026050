<template>
  <div v-if="users">
    <h1 class="ml-4">Consultant Clients</h1>
    <v-card class="mt-3">
      <v-card-title>
        <v-row>
          <!-- <v-col> -->
          <!-- <base-consultant-user-picker
              label="Search a clients name by typing here ..."
              clearable
              outlined
            /> -->
          <!-- </v-col> -->
          <!-- <v-col> -->
          <!-- <base-status-picker
              :chosen-status.sync="statusQuery"
              label="Status"
              outlined
              clearable
            /> -->
          <!-- </v-col> -->
          <v-col>
            <v-text-field
              v-model="search"
              label="Search"
              :fetch="filterItems"
              outlined
            />
          </v-col>
          <v-col>
            <download-excel
              class="text-right"
              :data="users.records"
              :fields="fields"
              worksheet="Clients"
              name="ConsultantClientList.xls"
            >
              <v-btn class="text-right" color="#0a2752" large dark>
                <h1>Download</h1>
              </v-btn>
            </download-excel>
          </v-col>
          <v-col>
            <v-dialog v-model="dialog3" width="800">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red lighten-2"
                  dark
                  large
                  v-bind="attrs"
                  v-on="on"
                >
                  <h1>Create New Client</h1>
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  <h1>Create New Client</h1>
                </v-card-title>

                <v-card-text>
                  <consultants-user-create />
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialog3 = false">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="users.records"
        loading="isLoading"
        :search="search"
        :hide-default-footer="true"
        transition="scale-transition"
      >
        <template v-slot:item.firstName="{ item }">
          <h4 color="primary">
            <router-link :to="'/consultant/user/' + item.id + '/edit'">{{
              item.firstName
            }}</router-link>
          </h4>
        </template>
        <template v-slot:item.branchName="{ item }">
          <v-chip pill light :color="getBranchColor(item.branchName)">
            <h3
              color="primary"
              class="pt-3"
              style="
                white-space: nowrap;
                width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ item.branchName ? item.branchName : "N/A" }}
            </h3>
          </v-chip>
        </template>
        <template v-slot:item.createdAt="{ item }">
          <v-row>
            <h3>Created At:</h3>
            <h3 color="primary">{{ item.createdAt | getFormattedDate }}</h3>
          </v-row>
          <v-row>
            <h3 color="primary">Updated At:</h3>
            <h3 color="primary">{{ item.updatedAt | getFormattedDate }}</h3>
          </v-row>
        </template>

        <template v-slot:item.role="{ item }">
          <v-chip
            label
            :color="
              item.role == 'admin'
                ? 'primary'
                : item.role == 'consultant'
                ? 'info'
                : item.role == 'lead'
                ? 'accent'
                : 'secondary'
            "
            >{{ item.role }}</v-chip
          >
        </template>
        <template v-slot:item.referral="{ item }">
          <v-chip
            label
            dark
            :color="
              item.referral == 'Bonitas'
                ? '#3d7539'
                : item.referral == 'Social Media'
                ? '#f5613b'
                : item.referral == 'Organic Website'
                ? '#284d8e'
                : '#af6aef'
            "
            >{{ item.referral ? item.referral : "N/A" }}</v-chip
          >
        </template>
        <template v-slot:item.actions="{ item }">
          <user-contact small :user="item" :me="me" />
          <v-btn
            fab
            small
            dark
            class="mr-2"
            @click="editItem(item)"
            color="primary"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <!-- <v-icon color="error" small @click="deleteItem(item)"
            >mdi-delete</v-icon
          > -->
        </template>
      </v-data-table>
    </v-card>
    <!-- <v-row>
      <v-col cols="12" sm="6" lg="11" class="mt-2 text-right mr-0 pr-0">
        <span class="mr-4 grey--text">
          Page {{ page }} of {{ numberOfPages }}
        </span>
        <v-btn fab small color="primary" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn fab small color="primary" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import UserContact from "../../../components/Base/User/UserContact.vue";
import ConsultantsUserCreate from "../../../components/Base/User/ConultantsUserCreate.vue";

export default {
  name: "Consultants Client",
  components: {
    UserContact,
    ConsultantsUserCreate,
  },
  data() {
    return {
      statusQuery: "",
      fields: {
        "Branch Name": "branchName",
        "First Name": "firstName",
        "Last Name": "lastName",
        email: "email",
        "Phone Number": "telNumber",
        "ID Number": "idNumber",
        "Created At": "createdAt",
      },
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "firstName",
        },
        { text: "Branch", align: "center", value: "branchName" },
        { text: "Referral", align: "center", value: "referral" },
        { text: "Email", value: "email" },
        {
          text: "Role",
          value: "role",
          filter: (value) => {
            if (!this.statusQuery) return true;
            return value === this.statusQuery;
          },
        },
        { text: "Created Date", value: "createdAt" },
        // { text: "Updated Date", value: "updatedAt" },
        { text: "Actions", align: "right", sortable: false, value: "actions" },
      ],
      search: "",
      users: [],
      totalRecords: 0,
      isLoading: false,
      page: 1,
      me: "",
    };
  },

  apollo: {
    me: gql`
      query {
        me {
          id
          branch
          email
          firstName
          lastName
          role
          branch
          branchName
        }
      }
    `,
    users: {
      query: gql`
        query Users($limit: Int, $page: Int, $query: JSON!) {
          users(limit: $limit, page: $page, query: $query) {
            records {
              id
              firstName
              lastName
              email
              role
              telNumber
              createdAt
              updatedAt
              consultant
              branch
              branchName
              referral
            }
            # paginator {
            #   userCount
            #   perPage
            #   pageCount
            #   currentPage
            #   slNo
            #   hasNextPage
            #   hasPrevPage
            #   next
            #   prev
            # }
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        const limit = 9999;
        return {
          limit: limit,
          page: this.page,
          query: { consultant: this.me.id },
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
  },
  computed: {
    numberOfPages() {
      return this.users.count;
    },
  },
  methods: {
    editItem(item) {
      this.$router.push(`/consultant/user/${item.id}/edit`);
    },
    getBranchColor(branchName) {
      if (branchName === "Medsafu Head Office") return "#feb809";
      else if (branchName === "1 Call Financial services") return "#cacaca";
      else if (branchName === "1Call Risk management") return "#cacaca";
      else if (branchName === "Medsafu Sinoville") return "#feb809";
      else return "#ffffff00";
    },

    // nextPage() {
    //   if (this.page + 1 <= this.numberOfPages) this.page += 1;
    // },
    // formerPage() {
    //   if (this.page - 1 >= 1) this.page -= 1;
    // },
    // updateItemsPerPage(number) {
    //   this.users.paginator.perPage = number;
    // },
    removeFalsy(obj) {
      const newObj = {};
      Object.keys(obj).forEach((prop) => {
        if (obj[prop]) {
          newObj[prop] = obj[prop];
        }
      });
      return newObj;
    },
  },
};
</script>

<style lang="scss" scoped></style>
